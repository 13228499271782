import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [

  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

  /* ------------------------------------------------------------------ REGISTRO, DATOS PERSONALES Y RECUPERACIÓN DE LA CLAVE DE ACCESO */

  {
    path: '/ingresar',
    name: 'Ingresar',
    component: () => import('../views/IngresarView.vue')
  },
  {
    path: '/registro',
    name: 'Registro',
    component: () => import('../views/RegistroView.vue')
  },
  {
    path: '/recuperarClave',
    name: 'Contraseña',
    component: () => import('../views/RecuperarClaveView.vue')
  },
  {
    path: '/datosUsuario',
    name: 'DatosUsuario',
    component: () => import('../views/DatosUsuarioView.vue')
  },
  {
    path: '/cambiarClave',
    name: 'cambiarClave',
    component: () => import('../views/cambiarClaveView.vue')
  },

  // ----------------------------------------------------------------------- usuario SUPERADMINISTRADOR-->

  {
    path: '/permisos/:accesoPermisos',
    name: 'Permisos',
    component: () => import('../views/PermisosView.vue')
  },
  {
    path: '/modulos/:accesoModulos',
    name: 'Módulos',
    component: () => import('../views/ModulosView.vue')
  },
  {
    path: '/roles/:accesoRoles',
    name: 'Roles',
    component: () => import('../views/RolesView.vue')
  },
  {
    path: '/crud/:tabla/:permiso',
    name: 'crud',
    component: () => import('../views/crud.vue')
  },

  // ----------------------------------------------------------------------- usuario GESTOR-->
  {
    path: '/gestionusuarios', 
    name: 'gestionUsuarios',
    component: () => import('../views/GestionUsuariosView.vue')
  },
  {
    path: '/udas/:accesoUdas',
    name: 'UDAs',
    component: () => import('../views/UdasView.vue')
  }, 
  {
    path: '/semestres/:accesoSemestres',
    name: 'Semestres',
    component: () => import('../views/SemestresView.vue')
  },
  {
    path: '/actividades/:accesoActividades',
    name: 'Actividades',
    component: () => import('../views/ActividadesView.vue')
  },
  {
    path: '/cargos/:accesoCargos',
    name: 'Cargos',
    component: () => import('../views/CargosView.vue')
  },

  // ----------------------------------------------------------------------- ACCESO A PLANIFICACION -->
  {
    path: '/planificacion/:accesoPlanificacion',
    name: 'Planificacion',
    component: () => import('../views/PlanificacionView.vue')
  },
  // ----------------------------------------------------------------------- DOCENTE-->
  {
    path: '/usuariosR', /* :accesoUsuarios */
    name: 'usuariosR',
    component: () => import('../views/UsuariosRView.vue')
  },
  {
    path: '/evaluaciones/:accesoEvaluaciones',
    name: 'Evaluaciones',
    component: () => import('../views/EvaluacionesView.vue')
  },
  // ----------------------------------------------------------------------- CATEDRA-->
  {
    path: '/usuariosD', /* :accesoUsuarios */
    name: 'usuariosD',
    component: () => import('../views/UsuariosDView.vue')
  },
  // ----------------------------------------------------------------------- RESIDENTE-->

  /* acceso a las evaluaciones propias con rol residente */
  /* el módulo evaluacionesR no necesita especificar el permiso porque es propio del rol RESIDENTE */ 
  {
    path: '/usuarioR', /* :accesoUsuarios */
    name: 'usuarioR',
    component: () => import('../views/UsuarioRView.vue')
  },
  //{
  //  path: '/evaluacionesR',
  //  name: 'EvaluacionResidente',
  //  component: () => import('../components/EvaluacionResidente.vue')
  //},
  /* acceso al portafolios propio con rol residente */
  {
    path: '/portafoliosR/:accesoPortafolios',
    name: 'PortafoliosResidente',
    component: () => import('../components/PortafoliosResidente.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
<template>

  <div class="row mt-4">

    <div class="col-6 bg-light mx-auto p-4">

      <form @submit.prevent>

        <header>
          <button type="button" class="close" aria-label="Close"  @click="cancelar">
            <span aria-hidden="true">&times;</span>
          </button>
        </header>

        <h4>Ingresar</h4>

        <div class="form-group">
          <label>Usuario:</label>
            <input 
              type="text" 
              v-model="datosFormulario.CEDULA"
              placeholder=""
              autocomplete="off"
              class="form-control" 
              :class="{ 'is-invalid': procesando && datosInvalidos }"
              @focus="resetEstado" 
              @keypress="resetEstado"
            />
        </div> <!-- form-group -->

        <div class="form-group">
          <label>Clave:</label>
            <input 
            type="password" 
            v-model="datosFormulario.CLAVE"
            placeholder=""
            autocomplete="off"
            class="form-control" 
            :class="{ 'is-invalid': procesando && datosInvalidos }"
            @focus="resetEstado" 
            @keypress="resetEstado"
            />
        </div> <!-- form-group -->

        <div class="form-group">
          <button id="btn_login"     class="btn btn-info  btn-sm ml-2" @click="enviarFormulario">Ingresar</button> 
          <!--
          <button id="btn_clave"     class="btn btn-info  btn-sm ml-2" @click="recuperar_clave">Olvidé mi contraseña</button>
          <button id="btn_registro"  class="btn btn-info  btn-sm ml-2" @click="registro">Registrarme</button>
          <button id="btn_cancelar"  class="btn btn-secondary  btn-sm  ml-2" @click="cancelar">Cancelar</button>
          -->
        </div> <!-- form-group -->

        <span class="seudo_enlace" @click="recuperar_clave">Olvidé mi contraseña</span>
        <span class="seudo_enlace" @click="registro">Registrarme</span>

        <div class="row">
          <div class="col-md-12">
            <div v-if="error && procesando" class="alert alert-danger" role="alert">
              Datos incompletos o no válidos
            </div>
            <div v-if="errorConn && procesando" class="alert alert-danger" role="alert">
              Error de acceso. Por favor reintente
            </div>
          </div>
        </div>

      </form> <!-- <form @submit.prevent> -->
    </div> <!-- <div class="col-6 bg-light mx-auto p-4"> -->
  </div> <!-- <div class="row mt-4"> -->
</template>

/* ================================================================================== */

<script>

  export default {

    name: 'formulario-login',

    data() { // ------------------------------------------------------------------ DATA
      return {

        procesando: false, 
        error: false, 
        errorConn: false, 

        datosFormulario: {
          CEDULA: '',
          CLAVE: '',
        },

      }
    }, /* data() { */

    mounted() { // ------------------------------------------------------------------ MOUNTED
      
      this.resetEstado();
      this.limpiarCampos();

    }, /* mounted */

    computed: { // ------------------------------------------------------------------ COMPUTED

      usuarioInvalido() {
        let invalido = true;
        if (this.datosFormulario.CEDULA !== null){
          invalido = false;
        }
        return invalido;
      }, // usuarioInvalido

      claveInvalida() {
        let invalido = true;
        if (this.datosFormulario.CLAVE !== null){
          invalido = false;
        }
        return invalido;
      }, // claveInvalida
      
      datosInvalidos() {
        return this.usuarioInvalido || this.claveInvalida;
      },/* datosInvalidos */
 
    },/* computed: { */

    methods: { // ------------------------------------------------------------------ METHODS

      limpiarCampos() {
        this.datosFormulario.CEDULA = null;
        this.datosFormulario.CLAVE = '';
      },

      resetEstado() {
        this.error = false;
        this.errorConn = false;
        this.procesando = false;
      },

      cancelar(){
        this.$emit('cancelar');
      }, /* cancelar */
                
      recuperar_clave() { // inicia el procedimiento de recuperación de la clave de acceso del usuario logueado

        this.$emit('recuperar-clave');

      }, // recuperar_clave
                
      registro() { // inicia el procedimiento de registro

        this.$emit('registro');

      }, // registro

      enviarFormulario() {

       //console.log('-----------------------------------  FormularioLogin ->  enviarFormulario');

        this.estadoInicial();
        if (this.datosInvalidos) {
         //console.log('datosInvalidos');
          this.error = true;
        }else{
         //console.log('else datosInvalidos');
          this.login();
        }

      }, /* enviarFormulario */

      async login() {
 
       //console.log('-----------------------------------  FormularioLogin -> login');

        try {  

          const url = 'https://catedradeurologia.uy/ws/login.php'; 
          //console.log('url: ' + url);
          const data = {CEDULA: this.datosFormulario.CEDULA, CLAVE: this.datosFormulario.CLAVE};
          //console.log('data: ',data);
          
          const response = await fetch(url, {
              method: 'PUT',
              body: JSON.stringify(data),
              headers: { 'Content-type': 'application/json; charset=UTF-8'},
          });
         
          const status = response.status;
          //console.log('response: ',response);
          //console.log('status: ',status);

          if (status==200){

            const usuario = await response.json();

            sessionStorage.setItem('JWT', 'tkn:urologia');
            sessionStorage.setItem('r', usuario.ID_ROL);
            sessionStorage.setItem('i', usuario.ID);
            const nombreUsuario = usuario.APELLIDOS + " " + usuario.NOMBRES;
            sessionStorage.setItem('u', nombreUsuario);

            this.error = false;
            this.errorConn = false;
            //console.log('usuario.ID_ROL: ',usuario.ID_ROL);
            this.$emit('enviar-login',usuario.ID_ROL);

          }else if (status==401){
            this.error = true;
          }else{
            this.errorConn = true;           
          }/* status==200 */

        } catch (error) {
            //console.error(error);
            this.errorConn = true;
        }

      },/* login */

      estadoInicial() {
        this.procesando = true;
        this.error = false;
        this.errorConn = false;
      },

    }, /* methods */

  }/* export default */

</script>

<style scoped>
  form {
    margin-bottom: 2rem;
  }

  .seudo_enlace + .seudo_enlace {
    margin-left: 1em;
    border-left: 1px solid #ccc;
    padding-left: 1em;
  }

  .seudo_enlace {
    color: #138496;
  }

  .seudo_enlace:hover {
    color:#1dcbe6;
  }

</style>
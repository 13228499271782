<template>
  <div class="home | text-center mt-4 flex">
    <img alt="Cátedra de Urología" src="../assets/iso-logo.png">
    <span class="text-white white-text">
      CATEDRA DE UROLOGÍA<br />
      PROF. DR. LEVIN RODRÍGUEZ<br />
      FACULTAD DE MEDICINA<br />
      UDELAR
    </span>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    /* HelloWorld */
  }
}
</script>

<style scoped>
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<template>

  <div id="app">
  
    <nav class="navbar navbar-expand-lg navbar-light bg-light">

      <div  class="container">
      
        <a class="navbar-brand" href="#">Urología</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- ----------------------------------------------------------------------- ROUTER = MENÚ --> 
        <div class="collapse navbar-collapse" id="navbarSupportedContent">

          <ul class="navbar-nav mr-auto">
            <!--
            <li class="nav-item active">
              <router-link to="/" class="nav-link">Inicio <span class="sr-only">(current)</span> </router-link>
            </li>
            -->
            <!-- ----------------------------------------------------------------------- ROUTER usuario logueado SUPERADMINISTRADOR-->

            <li class="nav-item"><router-link v-if="permisos && login" :to="{path: '/permisos/' + accesoPermisos}" class="nav-link">Permisos </router-link></li>
            <li class="nav-item"><router-link v-if="modulos && login"  :to="{path: '/modulos/' + accesoModulos}" class="nav-link">Módulos </router-link></li>
            <li class="nav-item"><router-link v-if="roles && login"  :to="{path: '/roles/' + accesoRoles}" class="nav-link">Roles </router-link></li>
            <li class="nav-item"><router-link v-if="crud && login"  :to="{path: '/crud/UNO/' + accesoCrud}" class="nav-link">CRUD </router-link></li>

            <!-- ----------------------------------------------------------------------- ROUTER usuario logueado GESTOR-->
          
            <li class="nav-item"><router-link v-if="usuarios && login && accesoUsuarios=='escritura'"  :to="{path: '/gestionusuarios/'}" class="nav-link"> Usuarios</router-link></li>
            <li class="nav-item"><router-link v-if="udas && login"  :to="{path: '/udas/' + accesoUdas}" class="nav-link">UDAs </router-link> </li>
            <li class="nav-item"><router-link v-if="semestres && login"  :to="{path: '/semestres/' + accesoSemestres}" class="nav-link">Semestres </router-link> </li>
            <li class="nav-item"><router-link v-if="actividades && login"  :to="{path: '/actividades/' + accesoActividades}" class="nav-link">Actividades </router-link> </li>
            <li class="nav-item"><router-link v-if="cargos && login"  :to="{path: '/cargos/' + accesoCargos}" class="nav-link">Cargos </router-link> </li>
            <li class="nav-item"><router-link v-if="planificacion && login"  :to="{path: '/planificacion/' + accesoPlanificacion}" class="nav-link">Planificacion </router-link> </li>

            <!-- ----------------------------------------------------------------------- ROUTER usuario logueado DOCENTE-->

            <li class="nav-item"><router-link v-if="evaluaciones && login"  :to="{path: '/evaluaciones/' + accesoEvaluaciones}" class="nav-link"> Evaluaciones</router-link> </li>
            <li class="nav-item"><router-link v-if="usuarios && login && usuariosResidentes"  :to="{path: '/usuariosR/'}" class="nav-link"> Residentes</router-link> </li>

            <!-- ----------------------------------------------------------------------- ROUTER usuario logueado CATEDRA-->

            <li class="nav-item"><router-link v-if="usuarios && login && usuariosDocentes"  :to="{path: '/usuariosD/'}" class="nav-link"> Docentes</router-link> </li>
          
            <!-- ----------------------------------------------------------------------- ROUTER usuario logueado RESIDENTE-->
            
            <li class="nav-item"><router-link v-if="evaluacionesR && login" :to="{path: '/usuarioR/'}" class="nav-link"> Evaluaciones</router-link> </li>
            <!--
            <li class="nav-item"><router-link v-if="evaluacionesR && login" :to="{path: '/evaluacionesR/'}" class="nav-link"> Evaluaciones</router-link> </li>
            -->
            <li class="nav-item"><router-link v-if="portafolioR && login"   :to="{path: '/portafoliosR/' + accesoPortafolios}" class="nav-link"> Portafolio</router-link></li>
          
            <!-- ------------------------------------------------------------------ USUARIO LOGUEADO --> 

            <li class="nav-item"><router-link v-if="login"   :to="{path: '/datosUsuario/'}" class="nav-link"> Mis datos</router-link></li>

          </ul>
          
          <!-- ------------------------------------------------------------------ BOTONES usuario NO logueado --> 

          <ul class="navbar-nav ml-auto">
            <!--
            <li class="nav-item">
              <button 
                v-if="!login"
                class="btn btn-info  btn-sm ml-2" 
                data-toggle="tooltip" 
                title="Solicitud de registro"
                @click="solicitud_registro"
                >         
                  Registro
              </button>
            </li>
            -->


            <!-- el botón de login se muestra si no hay un usuario logueado y no se está logueando -->
            <li class="nav-item">
              <button 
                v-if="!login && ocultarLogin"
                class="btn btn-info  btn-sm ml-2" 
                @click="mostrar_login" 
                data-toggle="tooltip" 
                title="Ingresar a la aplicación">
                  Ingresar
              </button>
            </li>

          </ul>
          
          <!-- ------------------------------------------------------------------ USUARIO LOGUEADO --> 

          <span v-if="login">
            {{ u }}

            <button 
              v-if="login"
              class="btn btn-info  btn-sm ml-2" 
              @click="procesar_logout" 
              data-toggle="tooltip" 
              title="Salir de la aplicación">
                Cerrar Sesión
            </button>

            <!--
            <button 
              v-if="login"
              class="btn btn-info  btn-sm ml-2" 
              @click="datos_usuario" 
              data-toggle="tooltip" 
              title="Actualizar datos personales">
                Mis datos
            </button>

            <button 
              v-if="login"
              class="btn btn-info  btn-sm ml-2" 
              @click="cambiar_clave" 
              data-toggle="tooltip" 
              title="Cambiar mi contraseña">
                Cambiar mi contraseña
            </button>
            -->

          </span>
    
        </div>

      </div>  <!-- <div  class="container">-->
    
    </nav>

    <div  class="container">

      <!--
      <div class="row">
        <div class="col-md-12 mt-4 texto-centrado">
          <h3>Cátedra de Urología</h3>
        </div>
      </div>
      -->

      <router-view/>
       
      <!-- ----------------------------------------------------------------------- FORMULARIO LOGIN -->

      <!-- el formulario de login se muestra si no hay un usuario logueado y se está logueando -->
    
      <div class="row" v-if="!login && !ocultarLogin" >
        <div class="col-md-12">
          <formulario-login  
            @enviar-login="procesar_login"
            @recuperar-clave="recuperar_clave"
            @registro="solicitud_registro"
            @cancelar="cancelar_login"
            />         
        </div>
      </div>

    </div>  <!-- <div  class="container">-->  

  </div> <!-- <div id="app" >-->   

</template>

/* ================================================================================== */

<script>

import FormularioLogin from '@/components/FormularioLogin.vue'

export default { // --------------------------------------------------------------------- export default

  components: { // ------------------------------------------------------------------------------------------------------ COMPONENTES

    FormularioLogin,

  }, /* components: {*/

  mounted() { // --------------------------------------------------------------------------------------------------------- MOUNTED
   
    // verifica la existencia previa de un usuario logueado
    const rolReload = sessionStorage.getItem('r');
    if(rolReload>0){
      this.obtenerModulosRol(rolReload);
      this.login = true;
    }

    const id = this.$route.params.ID
    sessionStorage.setItem('i', id);
    console.log('mounted--------------------------------------------------------------------');
    console.log('i en sessionStorage: ' + sessionStorage.getItem('i'));
    console.log('r en sessionStorage: ' + sessionStorage.getItem('r'));
    console.log('u en sessionStorage: ' + sessionStorage.getItem('u'));
    console.log('login: ' + this.login);

    // procesa el logout al cerrar la ventana
    window.addEventListener('beforeunload', this.procesar_logout); 

  }, /* mounted() {*/

  data() { // ------------------------------------------------------------------------------------------------------------ DATA 
    return {

      errorConn: false, 
      url:'', 
      urlSemestresSecuencia:'',
      tkn:'',

      login:false, 
      ocultarLogin:false, // mostrar u ocultar el formulario de login
      r:0, // rol del usuario logueado
      u:'',

      // módulos accesibles al rol instanciado y su acceso (lectura o escritura)

      // ----------------------------------------------------------------------- usuario SUPERADMINISTRADOR-->

      permisos:null,
      accesoPermisos:null,
      
      modulos:null,
      accesoModulos:null,
      
      roles:null,
      accesoRoles:null,
      
      crud:null,
      accesoCrud:null,

      // ----------------------------------------------------------------------- usuario GESTOR-->

      udas:null,
      accesoUdas:null,

      actividades:null,
      accesoActividades:null,
      
      cargos:null,
      accesoCargos:null,
      
      semestres:null,
      accesoSemestres:null,

      usuarios:null,
      accesoUsuarios:null,
      
      planificacion:null,
      accesoPlanificacion:null,

      // ----------------------------------------------------------------------- DOCENTE-->

      evaluaciones:null,
      accesoEvaluaciones:null,

      portafolios:null,
      accesoPortafolios:null,

      usuariosResidentes:null,
      usuariosDocentes:null,

      // ----------------------------------------------------------------------- RESIDENTE-->
    
      evaluacionesR:null,
      portafolioR:null,

    }    
  }, /* data */

  methods: { // ---------------------------------------------------------------------------------------------------------- METHODS

    // -- ------------------------------------------------------------------ BOTONES usuario NO logueado

    solicitud_registro() { // accede al formulario de solicitud de registro

      sessionStorage.clear(); 
      this.login = false; // desloguear cualquier usuario
      this.ocultarLogin = true;  // ocultar el formulario de login
      //console.log("solicitud_registro"); 
      this.$router.push('/registro'); // formulario de solicitud de registro

    }, // solicitud_registro
          
    recuperar_clave() { // inicia el procedimiento de recuperación de la clave de acceso del usuario logueado

      sessionStorage.clear();
      this.login = false;  // desloguear cualquier usuario
      this.ocultarLogin = true; // ocultar el formulario de login
      //console.log("recuperar_clave"); 
      this.$router.push('/recuperarClave'); // formulario de recuperación de clave

    }, // recuperar_clave

    // -- ------------------------------------------------------------------ LOGIN

    mostrar_login() { // muestra el formulario de login

      sessionStorage.clear();
      this.login = false;  // desloguear cualquier usuario
      this.ocultarLogin = false; // mostrar el formulario de login
      this.$router.push('/'); // portada

    },/* mostrar_login() { */

    procesar_login(rol) { // obtiene los módulos y permisos del rol logueado para componer el menu

      //console.log('----------------------------------- App -> procesar_login: ',rol);

      this.login = true; 
      this.semestreEnCurso();
      this.obtenerModulosRol(rol);
      this.r = rol;
      this.u = sessionStorage.getItem('u');
      this.$router.push('/');

    }, /* procesar_login(rol) { */
    
    async semestreEnCurso() { // carga la variable sem del sessionStorage

      this.urlSemestresSecuencia = "https://catedradeurologia.uy/ws/semestres_secuencia.php";
      this.tkn = 'Basic ' + sessionStorage.getItem('JWT'); 
      this.errorConn = false; 

      const fecha = new Date();
      //console.log('fecha: ' + fecha );
      const anio = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      let mesStr = '';

      if(mes<10){mesStr = ''.concat('0',mes)}else{mesStr = ''.concat(mes)}
      const dia = fecha.getDate();
      let diaStr = '';
      if(dia<10){diaStr = ''.concat('0',dia)}else{diaStr = ''.concat(dia)}
      //console.log('anio: ' + anio );
      //console.log('mes: ' + mes );
      //console.log('mesStr: ' + mesStr );
      //console.log('dia: ' + dia );
      //console.log('diaStr: ' + diaStr );

      try {
        const headers = new Headers();
        headers.append('authorization', this.tkn);
        headers.append('Content-type', 'application/json; charset=UTF-8');
        const init = {
          method: 'GET',
          headers
        };

        const urlFecha = this.urlSemestresSecuencia + '?anio=' + anio + '&mes=' + mesStr + '&dia=' + diaStr ;
        //console.log('urlFecha: ' + urlFecha);
        const response = await fetch(urlFecha,init);
        //console.log('response: ' + JSON.stringify(response));
        const semestre = await response.json();
        //console.log('semestre: ' + JSON.stringify(semestre));
        const status = response.status;
        //console.log(status);

        if (status==200){

          let sem = semestre[0].id_semestre;
          //console.log('semestreEnCurso: ' + sem);
          sessionStorage.setItem('sem', sem);

        }else{
          this.errorConn = true;          
        }/* status==200 */

      } catch (error) {
        //console.error(error);
        this.errorConn = true; 
      }

    },/* async semestreEnCurso { */
       
    async obtenerModulosRol(rol) {

      console.log('----------------------------------- App -> obtenerModulosRol: ',rol);

      this.url = 'https://catedradeurologia.uy/ws/roles_permisos_10.php';
      this.tkn = 'Basic ' + sessionStorage.getItem('JWT'); 
      this.errorConn = false; 

      try {
        const headers = new Headers();
        headers.append('authorization', this.tkn);
        headers.append('Content-type', 'application/json; charset=UTF-8');
        const init = {
          method: 'GET',
          headers
        };

        const urlID = this.url + '?ID_ROL=' + rol;
        //console.log('urlID: ' + urlID);
        const response = await fetch(urlID,init);
        //console.log('response: ' + JSON.stringify(response));
        const accesos = await response.json();
        //console.log('accesos: ' + JSON.stringify(accesos));
        const status = response.status;
        //console.log(status);

        if (status==200){

          // módulos accesibles al rol instanciado y su acceso (lectura o escritura)

          // ----------------------------------------------------------------------- usuario logueado SUPERADMINISTRADOR-->

          // La función de callback (element => element.MODULO=='permisos') se ejecuta para cada elemento en el array accesos. 
          // cargando en permisos los elementos que cumplen la condición

          this.permisos = accesos.find(element => element.MODULO=='permisos');
          // en la misma posición de accesoPermisos se carga el tipo de permiso
          this.accesoPermisos = this.permisos ? this.permisos.PERMISO : null;
          //console.log('permisos: ' + JSON.stringify(this.permisos));

          this.modulos = accesos.find(element => element.MODULO=='modulos');
          this.accesoModulos = this.modulos ? this.modulos.PERMISO : null;
          //console.log('modulos: ' + JSON.stringify(this.modulos));

          this.roles = accesos.find(element => element.MODULO=='roles');
          this.accesoRoles = this.roles ? this.roles.PERMISO : null;
          //console.log('roles: ' + JSON.stringify(this.roles));

          this.crud = accesos.find(element => element.MODULO=='crud');
          this.accesoCrud = this.crud ? this.crud.PERMISO : null;
          //console.log('crud: ' + JSON.stringify(this.crud));

          // ----------------------------------------------------------------------- usuario logueado GESTOR-->

          this.udas = accesos.find(element => element.MODULO=='udas');
          this.accesoUdas = this.udas ? this.udas.PERMISO : null;
          //console.log('udas: ' + JSON.stringify(this.udas));

          this.actividades = accesos.find(element => element.MODULO=='actividades');
          this.accesoActividades = this.actividades ? this.actividades.PERMISO : null;
          //console.log('actividades: ' + JSON.stringify(this.actividades));

          this.cargos = accesos.find(element => element.MODULO=='cargos');
          this.accesoCargos = this.cargos ? this.cargos.PERMISO : null;
          //console.log('cargos: ' + JSON.stringify(this.cargos));

          this.semestres = accesos.find(element => element.MODULO=='semestres');
          this.accesoSemestres = this.semestres ? this.semestres.PERMISO : null;
          //console.log('semestres: ' + JSON.stringify(this.semestres));
          
          this.usuarios = accesos.find(element => element.MODULO=='usuarios');
          this.accesoUsuarios = this.usuarios ? this.usuarios.PERMISO : null;
          //console.log('usuarios: ' + JSON.stringify(this.usuarios));
          //console.log('App.obtenerModulosRol -> accesoUsuarios: ',this.accesoUsuarios);

          this.planificacion = accesos.find(element => element.MODULO=='planificacion');
          this.accesoPlanificacion = this.planificacion ? this.planificacion.PERMISO : null;
          //console.log('planificacion: ' + JSON.stringify(this.planificacion));

          // ----------------------------------------------------------------------- usuario logueado DOCENTE-->

          this.evaluaciones = accesos.find(element => element.MODULO=='evaluaciones');
          this.accesoEvaluaciones = this.evaluaciones ? this.evaluaciones.PERMISO : null;
          //console.log('accesoEvaluaciones: ',this.accesoEvaluaciones);
          //console.log('evaluaciones: ' + JSON.stringify(this.evaluaciones));

          this.usuariosResidentes = (sessionStorage.getItem('r')==6 || sessionStorage.getItem('r')== 8) ? true : false ;
          //console.log('usuariosResidentes: ' + this.usuariosResidentes);

          this.portafolios = accesos.find(element => element.MODULO=='portafolios');
          this.accesoPortafolios = this.portafolios ? this.portafolios.PERMISO : null;
          //console.log('portafolios: ' + JSON.stringify(this.portafolios));

          // ----------------------------------------------------------------------- usuario logueado CATEDRA-->

          this.usuariosDocentes = sessionStorage.getItem('r') == 8 ? true : false ;
          //console.log('App.obtenerModulosRol -> usuariosDocentes: ' + this.usuariosDocentes);

          // ----------------------------------------------------------------------- usuario logueado RESIDENTE-->

          /* el módulo evaluacionesR no necesita especificar el permiso porque es propio del rol RESIDENTE */
          this.evaluacionesR = sessionStorage.getItem('r') == 5 ? true : false ;
          //console.log('App.obtenerModulosRol -> evaluacionesR: ' + JSON.stringify(this.evaluacionesR));

          this.portafolioR = sessionStorage.getItem('r') == 5 ? true : false ;
          //console.log('portafolioR: ' + this.portafolioR);
  
        }else{
          this.errorConn = true;          
        }/* status==200 */

      } catch (error) {
        //console.error(error);
        this.errorConn = true; 
      }

    },/* async obtenerModulosRol(rol) { */

    // -- ------------------------------------------------------------------ BOTONES usuario logueado 

    datos_usuario() { // accede a la ficha personal del usuario logueado

      sessionStorage.setItem('ClaveUsuario','usuario');
      this.$router.push('/datosUsuario');

    },/*  datos_usuario() { */

    cambiar_clave() { // accede formulario para cambiar la contraseña

      this.$router.push('/cambiarClave');

    },/*  datos_usuario() { */

    procesar_logout() { // limpia la session y muestra la portada de inicio

      sessionStorage.clear();
      this.login = false; 
      this.ocultarLogin = true; 
      this.$router.push('/');

    },/* procesar_logout() { */

    cancelar_login() { // limpia la session y muestra la portada de inicio

      this.ocultarLogin = true; 
      this.$router.push('/');

    },/* cancelar_login() { */

  } // --------------------------------------------------------------------- METHODS

} // --------------------------------------------------------------------- export default

</script>

/* ================================================================================== */

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  background: url('./assets/fondo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  /* filter: grayscale(100%); no permite ver los colores en los mensajes de alerta, etc */
  min-height: 100dvh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.texto-centrado {
  text-align: center; 
}
</style>